import React from "react";

export class ButtonCost extends React.Component{

    
    render(){
        return(
           <button id={this.props.id} onClick={console.log(this.location)} className="cost-button">₴ {this.props.id}</button>
        )
    }
}



