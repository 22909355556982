import React, { useEffect, useState } from "react";
import "../scss/content.scss"
import {ModailCost} from "../jsx/modail"
// import Girl from "../img/girl.png"
import Logo from "../img/logo.png"
import Kh from "../img/map.png"





export function Content(){
    const [iW,setIW] = useState(window.innerWidth)
    useEffect(()=>{
        window.onresize = ()=>{
            setIW(window.innerWidth)
        }
    })

    return(
        <div className="content">
            <img src={Logo} alt="" className={iW>850? "logo": "logo logo__mini"}/>
            <div className="content_midle">
                
                {
                    iW>850? <img className="img-screen" src={Kh} alt="img" />:""
                }
                <div className="case">
                    <span>ДОПОМОЖИ УКРАЇНІ ЗАРАЗ</span>
                    <ModailCost></ModailCost>
                    {
                        iW>850? "":<img className="img-screen" src={Kh} alt="img" />
                    }
                </div>
            </div>
        </div>
    )
}

export function Footer(){
    return(
        <footer className="footer">
            <div className="dop-info">
                <span className="dop-info_title">ФОНД КОМПЕСНСУЄ ВАРТІСТЬ ПАЛЬНОГО ДЛЯ ДОСТАВКИ ГУМАНІТАРНИХ ВАНТАЖІВ.</span><br />
                <span className="dop-info_sub-title">Зібрані кошти будуть направлені в БФ "РОЗВИТКУ ГРОМАД" </span><br />
                <span className="dop-info_info">Як працює Фонд?</span>
                <ul className="dop-info_list">
                    <li>Ми залучаємо кошти від донорів в Україні та країнах ЄС, а також співпрацюємо з волонтерами.</li>
                    <li>Ми купуємо талони на пальне в Україні, або компенсуємо витрати водіїв, даючи можливість небайдужим  людям надсилати гуманітарну допомогу з усього світу, а українцям отримуватм її по всій території України. </li>
                    <li> Також, закуповуємо товари медичної групи, а якщо якісь неможливо придбати в Україні,  замовляємо за кордоном. </li>
                    <li>Співпрацюємо з міжнародними благодійними фондами Gift of the Givers, Spoetnik, та інші. Детальніше за посиланням:<a href="https://uhelp.org.ua/">uhelp.org.ua</a>  </li>
                </ul>
            </div>
            <span className="footer_title"></span>
            <span className="footer_sub-title">©2022 БО "БФ "УКРАЇНСЬКА ОСВІТНЯ ФУНДАЦІЯ". All rights reserved.</span>
        </footer>
    )
}